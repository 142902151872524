<template>
  <div class="expertbase">
    <!-- 头部 -->
    <app-header @getHeight="getHeight" :phone="phone" />

    <div class="max_width min_width">
      <div
        class="flex align_center padtb_20"
        :style="{ 'margin-top': offsetHeight + 'px' }"
      >
        <div class="f838 size_14">当前位置：</div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item class="d8f">专家库</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <section class="marb_30 bgfff">
        <!-- 搜索 -->
        <div class="flex align_center jus_content marb_10">
          <div class="border_title">专家库</div>
          <div class="width_50 expert_search">
            <el-input placeholder="请输入关键词" v-model="keyword" @keyup.enter="searchData">
              <i slot="suffix" class="el-icon-search" @click="searchData"></i>
            </el-input>
          </div>
        </div>

        <!-- 列表 -->
        <div>
          <div>
            <el-table
              :data="listsItem"
              height="528"
              ref="tableExpert"
              :header-cell-style="{ background: '#0D8EFF', color: '#FFFFFF' }"
              stripe
            >
              <el-table-column prop="unit_name" label="姓名" align="center">
              </el-table-column>

              <el-table-column
                prop="contacts"
                label="专业"
                align="center"
              ></el-table-column>

              <el-table-column prop="sectors" label="工作单位" align="center">
              </el-table-column>

              <el-table-column prop="level" label="职称/职务" align="center">
              </el-table-column>
            </el-table>
          </div>

          <!-- 分页 -->
          <div class="width flex align_center jus_center padtb_30 relative">
            <app-mypaginations
              :pagSize="paginations"
              :allData="tableExpert"
              @setHandleCurrent="handleCurrent"
              @setHandleSize="handleSize"
              @setPage="setPage"
              @setSize="setSize"
            />
          </div>
        </div>
      </section>
    </div>

    <!-- 尾部 -->
    <app-footer @phone="getPhone" />
  </div>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
import MyPaginations from "@/components/MyPaginations/mypaginations.vue";

export default {
  data() {
    return {
      // 分页
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      listsItem: [],
      keyword: "",
      tableExpert: [],
      // 数据总条数
      count: 0,
      // 是否数据为空
      offsetHeight: 0,
      phone: "",
    };
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
    "app-mypaginations": MyPaginations,
  },
  methods: {
    getPhone(phone) {
      this.phone = phone;
    },
    // 获取头部高度
    getHeight(data) {
      this.offsetHeight = data;
    },
    setPage(data) {
      this.paginations.page_index = data;
      console.log(117, data);
      this.getExpertList();
    },
    setSize(data) {
      this.paginations.page_index = 1;
      this.paginations.page_size = data;

      this.getExpertList();
    },
    handleCurrent(item) {
      this.listsItem = item;
    },
    handleSize(item) {
      this.listsItem = item;
    },
    //重置分页
    resetPaginations() {
      // 总页数
      this.paginations.total = this.count;
      // 设置默认分页数据
      this.listsItem = this.tableExpert.filter((item, index) => {
        return index < this.paginations.page_size;
      });
    },
    searchData() {
      this.paginations.page_index = 1;
      this.tableExpert = [];

      this.getExpertList();
    },
    // 专家库
    getExpertList() {
      let data = {
        type: 2,
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
        keyword: this.keyword,
      };

      this.$post("service_list", data).then((res) => {
        if (res.status == 1) {
          this.tableExpert = res.result ? res.result : [];
          this.count = res.count;

          this.resetPaginations();
        }
      });
    },
  },
  mounted() {
    this.getExpertList();
  },
};
</script>

<style scoped>
@import url("./expertbase.css");
</style>
